<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">On-trip Services</h1>
						</div>
						<router-link to="/on-trip-services/add" class="btn btn-primary"
							>Add Service</router-link
							>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<v-server-table
							ref="servicesTable"
							selectable
							:columns="columns"
							:options="options"
							@row-click="showService"
							>
							<div slot="Item" slot-scope="props">
								<div class="d-flex align-items-center">
									<div
										class="avatar avatar-sm"
										v-if="props.row.image && props.row.image != 'NULL'"
										>
										<img :src="props.row.image" class="avatar-img" alt="Bus" />
									</div>
									<p class="ml-2 mt-2">
										{{ props.row.name }}
									</p>
								</div>
							</div>
							<div slot="Status" slot-scope="props">
								<span>{{
									parseInt(props.row.status, 10) === 1 ? 'Active' : 'Non-active'
								}}</span>
							</div>
							<div slot="Price" slot-scope="props">
								<span>&#x20A6;{{ props.row.price | money }}</span>
							</div>
							<div slot="Quantity" slot-scope="props">
								<span>{{ props.row.quantity }}</span>
							</div>
						</v-server-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data () {
    return {
      columns: ['Item', 'Status', 'Price', 'Quantity'],
      options: {
        filterable: true,
        requestFunction: (data) => {
          return this.fetchServices(data)
        }
      }
    }
  },
  methods: {
    async fetchServices (params) {
      const limit = params ? params.limit : 10
      const page = params ? params.page : 1
      const query = params ? params.query : ''

      const baseUrl = query ? '/v1/services/search' : '/v1/services'

      const url = `${baseUrl}?limit=${limit}&page=${page}&`

      const result = await this.axios({
        url,
        method: query ? 'POST' : 'GET',
        data: {
          search_term: query
        }
      })

      const data = result.data.data ? result.data.data : result.data

      const newResult = {
        data: data,
        count: data.length
      }

      return {
        data: newResult
      }
    },
    showService (service) {
      this.$router.push({
        name: 'ShowService',
        params: { serviceId: service.row.id }
      })
    }
  }
}
</script>

<style lang="css" scoped>
.VueTables__search {
  padding-left: 10px !important;
}
</style>
